<template>
  <div class="text-page-container">
    <div class="text-page-content">
      <slot/>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
  .text-page-container {
    width: calc(100% - 16px);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-page-content {
      max-width: 800px;
      padding: 4px 16px;
      border-radius: 4px;
      background-color: #000000A0;
    }
  }
</style>
