import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import ImpressumView from '../views/ImpressumView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/qr',
    name: 'qr-entry',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
