<template>
  <div class="home">
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>

</style>
