<template>
  <div class="holder">

  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
const props = defineProps({
  img: String
})
const bgimage = computed(() => `url(${props.img})`)
</script>

<style lang="scss" scoped>
  .holder {
    position: fixed;
    top: -5vh;
    left: -5vw;
    width: 110vw;
    height: 110vh;
    background-position: 50% 50%;
    background-size: cover;
    filter: blur(20px);
    background-repeat: no-repeat;
    background-image: v-bind(bgimage);
    z-index: -1;
    background-color: #000;
    opacity: .7;
  }
</style>
