<template>
  <nav>
    <div class="left">

      <router-link to="/">home</router-link> |
      <router-link to="/about">about</router-link> |
      <!--<router-link to="/contact">Kontakt</router-link> |-->
      <router-link to="/impressum">impressum</router-link>
    </div>
    <div class="right socials">
      <!-- ICONS: https://www.svgrepo.com/collection/social-media-filled-border-radius-logos/ -->
      <a href="https://www.facebook.com/nightbanemetal/" target="_blank"><img src="@/assets/icons/facebook.svg"></a>
      <a href="https://www.instagram.com/nightbanemetal/" target="_blank"><img src="@/assets/icons/instagram.svg"></a>
      <a href="https://www.youtube.com/@NightbaneMetal" target="_blank"><img src="@/assets/icons/youtube.svg"></a>
      <a href="https://www.tiktok.com/@nightbanemetal" target="_blank"><img src="@/assets/icons/tiktok.svg"></a>
      <a href="https://www.backstagepro.de/nightbane" target="_blank"><img src="@/assets/icons/backstagepro.svg"></a>
    </div>
  </nav>
  <router-view/>
  <BgImage img="/img/FullBg.png"/>
  <div :class="{layout: isHome}">
    <div class="player-cont">
      <CoverPlayer :is-mini="!isHome"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BgImage from '@/components/bg-image.vue'
import CoverPlayer from '@/components/cover-player.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const isHome = computed(() => route.fullPath === '/')

</script>

<style lang="scss">

html, body {
  padding: 0;
  margin: 0;
}
html {
  background-color: #000000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #F2F2F2;
}

a {
  color: #FFFFFFCC;
  text-decoration: none;
  &:hover {
    color: #FFFFFFFF;
  }
}

nav {
  text-align: center;
  margin: auto;
  padding: 1rem 0;
  font-size: 1.4rem;
  max-width: 500px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #FFFFFFCC;
    text-decoration: none;

    &:hover {
      color: #FFFFFFFF;
    }
  }

  a.router-link-active {
    text-decoration: underline;
  }

  .socials a {
    opacity: .7;
    transition: all .2s linear;
    transform: none;
    img{
      padding: 0 .2rem;
      width: 30px;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .player-cont {
      max-width: 500px;
    }
  }

</style>
